
import { Vue, Component } from "vue-property-decorator";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgBoleto extends Vue {

  filter: {
    empresaId:number;
    dataEmissaoInicial:string;
    dataEmissaoFinal:string;
  } = {
    empresaId: 0,
    dataEmissaoInicial: "",
    dataEmissaoFinal: "",
  }; 

  valid = true;
  empresas: any = [];


   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório de Envio de Sms', disabled: true, href: '/financeiro/painel/despesas'}
  ]


  mounted() { 
    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,nomeFantasia', '').then(
    (res) => {
      this.empresas = res.data.items;
    });
  }


  Visualizar() {
    if(!this.filter.dataEmissaoInicial || !this.filter.dataEmissaoFinal){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos um dos campos.", "warning");
        return true;
    }    
    let routeLink = this.$router.resolve({ 
      name: "RelSmsTotalEnviado",
      query: {
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
        dataEmissaoInicial: this.filter.dataEmissaoInicial ? this.filter.dataEmissaoInicial.toString() : "",
        dataEmissaoFinal: this.filter.dataEmissaoFinal ? this.filter.dataEmissaoFinal.toString() : "",
      },
    });
    window.open(routeLink.href, "_blank");
  }
}
